import React from 'react'
import im from './img/number1.png'
import ima from './img/number2.png'
import imaa from './img/numbre3.png'
import b from './img/one.png'
import bb from './img/two.png'
import bbb from './img/three.png'
///import bbbb from './img/dot.png'
import g from './img/substract.png'
import './Business.css';
import { Container } from 'react-bootstrap';
import Rotate from 'react-reveal/Rotate';












export const Business = () => (
  <Container>



  <div className="t">
     <br></br>
     <Rotate top right>
       
    <h2 className="t">חוות דעת משרד הבריאות לתכנון העסק &nbsp; <img className="si" src={im} alt="2"/></h2> 
    <br></br><br></br>
     
    <h2 className="t">הגשת הבקשה לרישיון העסק  &nbsp;  <img className="si"  src={ima} alt="2"/> </h2> 
    <br></br><br></br>
     
    <h2 className="t" style={{}} >  , לאחר הגשת הבקשה לרישיון העסק ובדיקת תקינותה   &nbsp; <img className="si" src={imaa} alt="2"/>  </h2> 
     <h2 className="t"> מתנהל טיפול בתיק מול מחלקת הנדסה לעסקים&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>

    <br></br><br></br><br></br>



    <div>
  <h5 className="t">  מחלקת הנדסה לעסקים מחולקת לשתי יחידות <img src={g} alt="2"/> יחידת תכנון הנדסי ויחידת רישוי הנדסי   </h5>
 

  </div>
  <br></br><br></br>

  <div className="text">
  <h3 className="t">- היחידה לתכנון הנדסי &nbsp; <img className="si" src={b} alt="2"/> </h3>
  

 <p style={{}} >  מטפלת בתכניות לניהול עסק שהוגשו על ידי בעל מקצוע מוסמך, לפי חוק רישוי עסקים. המחלקה בודקת את תכנון העסק, חלוקת מדורים, שטחים הנדרשים לעסק  <p>.לעסק, האם הוסדר תכנון נגישות לאנשים עם מוגבלויות, תכנית לפרגוד לעסק ועוד</p></p>


 <br></br>

  <h3 className="t">- היחידה לרישוי הנדסי &nbsp; <img className="si" src={bb} alt="2"/> </h3>
<p style={{}} >  בודקת את חוקיות המבנה לפי חוק התכנון והבנייה, את השימוש בנכס לפי היתר בנייה או תב"ע (תכנון בניין עיר) ובוחנת האם השימוש במקום הינו לפי השימושים המותרים על פי היתר/תב"ע. במידה והשימוש אינו בהתאם לשימושים המותרים במקום, המחלקה תפנה את הבקשה להליך שימוש חורג, אשר יטופל ביחידת רישוי הנדסי<p style={{}} >  . ולא באגף רישוי בנייה </p>  </p>

<br></br><br></br>

<h3 className="t">בהמשך התהליך התיק עובר לטיפול של  &nbsp; <img className="si" src={bbb} alt="2"/> </h3>
<h3 className="t">- מחלקת ביקורת עסקים&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h3>


<p></p>
<p> מחלקת ביקורת עסקים של אגף רישוי עסקים מבצעת ביקורות בעסקים המגישים בקשה לרישיון, בחידושי רישיונות ובביקורות מוקדמות לפי הצורך. במהלך הביקורת נבדקים התאמת העסק לתכנית המאושרת, תנאי תברואה נאותים על פי חוק רישוי עסקים ותנאים נוספים לפי מהות העסק . במפרט האחיד באתר העירייה ניתן לראות את תהליך הטיפול בבקשה במחלקה, טיפים חשובים וטפסי צ'ק ליסט להכנת העסק <p style={{}} >.לקראת הביקורת </p></p> 

  </div>








  </Rotate>
   </div>



   </Container>




)

