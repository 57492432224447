import React, {Component} from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Fade from 'react-reveal/Fade';
import d from './img/map.png';
import ddd from './img/mail.png'
import './About.css';
import { Button } from 'react-bootstrap';
////import GoogleMapReact from 'google-map-react';

///const myLatLng = {
  //  lat:32.084890,
 //   lng: 34.800629
 // };
//
////const mapStyles = {

 // width: '40%',
 //   height: '40%',
 //   left: 90,
 //   right:0,
  //  top: 20,
  //  bottom: 110,
  //  margin: 0,
 //   position: myLatLng,
  ///  display:'flex',
  //  flexdirection:'row',
 //   justifycontent:'space-evenly',
  //  alignitems:'flex-end',
  //  backgroundattachment:'fixed', 
  //  backgroundposition:'center' 
  


    
 /// };




class About extends Component {
render() {

return (
    <div>


    <div className="textttttt" >
    <Fade top>
    <p></p><p></p><p></p><p></p>
   

<p > משרדנו - מתחם הבורסה, מגדלי פז - רחוב שוהם 5, רמת גן  &nbsp; <img className="im" src={d} alt="1"/> </p>
<br></br>


   <Button  className="textttttt" a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to= shani.aviv.arch@gmail.com" target="_blank"   variant="Light" >  shani.aviv.arch@gmail.com - שלחו לנו הודעה ונחזור אליכם &nbsp;<img className="im" src={ddd} alt="1"/>  </Button>
    </Fade>

    </div>
 <div class="google-maps" >

 <br></br>


<Map 
google={this.props.google}
zoom={15}
fullscreenControl= {true}
zoomControl= {true}
streetViewControl= {true}





initialCenter={{ lat: 32.084890, lng: 34.800629}} 


>


<Marker position={{ lat: 32.084890, lng: 34.800629}}  title="רחוב שוהם 5"  />
</Map>


 </div>

 <div className="abc">


</div>



 </div>




 );



 }

}


export default GoogleApiWrapper({
    apiKey: ("AIzaSyC2H55uGYHc55B2L3CwQd2Jzijoj_isaU0"),
    language:("hebrew"),
    region:("jp"),
    
})(About);



///const mapStyles = {

 //   width: '40%',
 //   height: '40%',
 //   left: 0,
 //   top: 0,
 //   margin: 10,
 //   padding: 150,
///    position: 'absolute',
  //  display:'flex',
 //   flexdirection:'row',
  //  justifycontent:'space-evenly',
//   alignitems:'flex-end',
 //  backgroundattachment:'fixed', 
 //  backgroundposition:'center' 
  
  


    
 // };


/// <Map 
///google={this.props.google}
////zoom={14}





/////initialCenter={{ lat: 32.084890, lng: 34.800629}} 


///>


  
///<Marker position={{ lat: 32.084890, lng: 34.800629}}  title="רחוב שוהם 5"  />
///</Map>

//////////<a href="tel:052-4816141" target="_blank"> <p><span></span>  יצירת קשר - 052-4816141 &nbsp; <img className="im" src={dd} alt="1"/> </p></a>