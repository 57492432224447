import React from 'react'
import { Container   } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
//import ReactImageMagnify from 'react-image-magnify';
import h from './img/20.png'
import hh from './img/21.png'
import hhh from './img/22.png'
import hhhh from './img/23.png'
import hhhhh from './img/24.png'
import hhhhhh from './img/25.png'
import hhhhhhh from './img/26.png'
import hhhhhhhh from './img/27.png'
import hhhhhhhhh from './img/28.png'
import hhhhhhhhhh from './img/29.png'
import hhhhhhhhhhh from './img/30.png'
import hhhhhhhhhhhh from './img/31.png'
import hhhhhhhhhhhhh from './img/32.png'
///import watchImg300 from './img/watc.png'
//import watchImg1200 from './img/watc.png'
import './customers.css';



export const customers = () => (

<Container  >



<div  >
<br></br><br></br><br></br><br></br>

<table   className="ta">
<Fade top>
  
  <tr>
    <th ><img  className="immm"  src={h} alt="1"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>

    <th><img  className="immm"   src={hhhhhhhhhhh} alt="1"/>&nbsp;&nbsp;</th>
 
   
    <th ><img className="immm"   src={hhh} alt="1"/></th>


 

  </tr>
  <br></br>  <br></br>
<tr>
<th ><img className="immm"  src={hhhh}  alt="1"/>   </th>
    <th ><img className="immm"  src={hhhhhhhhhhhhh}  alt="1"/>   </th>
<th ><img className="immm"   src={hhhhhhh} alt="1"/></th>
   

   
    <th></th>


  </tr>

  <br></br>  <br></br>

 <tr>
 <th><img className="immm"   src={hhhhhhhhhh} alt="1"/></th>

   
<th ><img className="immm"   src={hhhhhh} alt="1"/></th>
<th><img className="immm"   src={hh}  alt="1"/></th>

   


    <th></th>
    <th ></th>
    <th></th>

  </tr>
  <br></br>  <br></br>
  <br></br>  <br></br>

 <tr>


   

<th ><img className="immm"  src={hhhhhhhh}  alt="1"/></th>
   
    <th><img className="immm"   src={hhhhhhhhhhhh}  alt="1"/>
    </th>
 
   
    <th ><img className="immm"   src={hhhhhhhhh} alt="1"/></th>


  </tr>
  <br></br>  <br></br>
  <tr>




    <th ></th>

    <th>    <img className="immm"  src={hhhhh} alt="1"/></th>
    <th ></th>
    <th></th>

  </tr>
  </Fade>
</table>



<br></br>













</div>

  </Container>




)





///<div style={{width:'442px',height:'113px'}} >
///<ReactImageMagnify {...{
 //   smallImage: {
  //      alt: 'Wristwatch by Ted Baker London',
  //      isFluidWidth: true,
   //     src: watchImg300
 //   },
//   largeImage: {
   //   src: watchImg1200,
  //    width: 1200,
  //    height: 1800
 // }
//}} />
///</div>