import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar,Button } from 'react-bootstrap';
import './NavigationBar.css';
import styled from 'styled-components';
import ima from '../img/logooo.png'
import n from '../img/phone.png'
import Fade from 'react-reveal/Fade';


setTimeout(() => {debugger;}, 5000)


const Styles = styled.div`





////showe all screen auto
margin-top: -7.2%;

position: fixed;
position: sticky;
z-index: 999;
justify-content: space-between;
width: 100%;
height: 100%;
left: 0;
top: 0;







.navbar {
  background-color: #FFFFFF;

  opacity: 0.9;

}

.img{
 
  height: 42px;
  width: 42px;
  z-index: 1;
  text-align: right;
    
   
}

}
.text{
  font-size: 18px;
  font-style: normal;
  text-align: right;
  font-weight: bold;
  color: '#000000';


  opacity: 1.5;

}

.te{
  color: '#bbb';
  font-size: 15px;
  font-weight: bold;
  background-color:  #0693e3  ;
  font-family:david;
  border-radius: 20px;
  text-align: right;

}

.tex{
  font-size: 18px;
  font-weight: bold;
  color: '#FFFFFF';
  text-align: right;
  
 

}

a, .navbar-brand, .navbar-nav .nav-link {

  color: '#FFFFFF';
  
  &:hover {
    color:'#FFFFFF';
   
  }

}
`


///function takla(modal)    {
 /// alert(
  ///   'נגישות האתר נמצאת בטיפול , עמכם הסליחה',
     
     
   /// );


///}



function scrollStep() {
  window.scrollBy( { 

    top: 1000,
    behavior: 'smooth'

});
}

export const NavigationBar = () =>   (
  
 <Styles >
 <head>
 <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script>

 </head>



<Navbar   bg="light" expand="lg" class="container-1" >
<Fade bottom>
    <a href="tel:052-4816141"><Button  className="te" variant="light">לייעוץ חינם ! צור קשר</Button></a>
    </Fade>
    
    <Navbar.Collapse  id="basic-navbar-nav">

    <Nav  className="ml-auto" >
    
           <div  className="box1"  > 
          <Nav.Item  >
            <Nav.Link >
            <Link style={{color: "black" }}  onClick= {scrollStep}  className="tex"  to="/">דף הבית</Link>

            </Nav.Link>
          </Nav.Item>
          </div>
          <div className="box2">
          <Nav.Item >
            <Nav.Link  >
            <Link   style={{color: "black" }} onClick= {scrollStep}  className="tex"   to="/אודות"> אודותינו</Link>
            
            </Nav.Link>
     
          
          </Nav.Item>
          </div>
          <div className="box3">
          <Nav.Item  >
          <Nav.Link >
            <Link style={{color: "black" }} onClick= {scrollStep} className="tex"  to="/מבין לקוחותינו"> מבין לקוחותינו</Link>
            
            </Nav.Link>
          </Nav.Item>
          </div>
          <div className="box4">
          <Nav.Item  >
          <Nav.Link class="box4">
            <Link style={{color: "black" }} onClick= {scrollStep} className="tex"  to="/יצירת קשר">יצירת קשר</Link>
            
            </Nav.Link>
          </Nav.Item>
          </div>
          <div className="box5">
          <Nav.Item >
          <Nav.Link class="box5">
            <Link style={{color: "black" }} onClick= {scrollStep} className="tex"  to="/תקנון">תקנון האתר</Link>
            
            </Nav.Link>
          </Nav.Item>
          </div>
          <Nav.Item >
          <Nav.Link>

   
            
            </Nav.Link>
          </Nav.Item>
        </Nav>
        </Navbar.Collapse>
    
   </Navbar>
  
   <Nav  bg="primary" variant="dark" className="imgg" >

</Nav>


<Navbar  bg="light" expand="lg">

  <Nav  className="ml-auto">


  <Fade bottom>
  <Navbar.Brand  style={{fontWeight:"bold" }} > רישוי עסקים ישראל&nbsp;&nbsp;<img className="aaa" src={ima} alt="1"/>  </Navbar.Brand>  
  </Fade >



  
  </Nav>

  </Navbar>





       
    
   


  



</Styles>

)

////<Navbar bg="dark" variant="dark">

///////////  <Navbar.Toggle style={{position: "fixed" , marginRight:"auto" , textAlign:"center"}}   class="navbar-toggler navbar-toggler-right"  aria-controls="basic-navbar-nav"  />
