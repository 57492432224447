
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { Business } from './Business';
import { Accessibility } from './Accessibility';
import { Permitforuseexceeds } from './Permitforuseexceeds';
import  About  from './About';
import  Contact  from './Contact';
import { Websiteterms } from './Websiteterms';
import { customers } from './customers';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';
import Navigationdowen  from './components/Navigationdowen';
import Screen from './components/Screen'; 
import HamburgerMenu from './components/Nav/HamburgerMenu';
////import Burger from './components/Nav/Burger';
import { AccessibilityWidget } from 'react-accessibility';
import FloatingWhatsApp from 'react-floating-whatsapp'







class App extends Component {
  render() {
    return (
     
      <React.Fragment>
        <Router>
  
        < NavigationBar /> 
        <HamburgerMenu/>

          <Screen />
      
          <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/עסקים" component={Business} />
              <Route path="/נגישות" component={Accessibility} />
              <Route path="/היתרים" component={Permitforuseexceeds} />
              <Route path="/אודות" component={About} />
              <Route path="/יצירת קשר" component={Contact} />
              <Route path="/מבין לקוחותינו" component={customers} />
              <Route path="/תקנון" component={Websiteterms} />
           
           

             
              </Switch>
         
          
              <AccessibilityWidget />
              <FloatingWhatsApp  />


         
          
        </Router>

        <Navigationdowen/>

      </React.Fragment>
      
         

    );
  }
}

export default App;






