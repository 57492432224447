import React from 'react';
import { Button } from 'react-bootstrap';
import back from '../img/back2.png'
import './Screen.css';
import { Link } from 'react-router-dom';
import powerslap from "./video/55.mp4";





function scrollStep() {
  window.scrollBy( { 

    top: 800,
    behavior: 'smooth'

});
}






function Screen() {
  return (

    <div  className="overlay">
            <video className="viv" autoPlay loop muted >

<source src={powerslap} type="video/mp4"/>

</video>

      <Button onClick= {scrollStep} type="submit" className="Button"  variant="danger" size="lg"  >
        <img className="Buttonback" src={back} alt="1"  /> <Link className="texttitle" to="/היתרים"> &nbsp;היתרים </Link>
    </Button>


    <Button onClick= {scrollStep} type="submit"  className="Button"  variant="success" size="lg">
        <img className="Buttonback" src={back} alt="1" /> <Link className="texttitle" to="/נגישות">&nbsp;נגישות</Link>
      
    </Button>



      <Button onClick= {scrollStep} type="submit" className="Button"  variant="primary" size="lg">
        <img className="Buttonback" src={back} alt="1" /><Link className="texttitle" to="/עסקים">&nbsp;עסקים</Link>
    </Button>


    <head>
 <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script>

 </head>

    

 
    </div>



          
 

  )
}

export default Screen;





