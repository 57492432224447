import React from 'react'
import './Home.css';
import i from './img/2.png'
import im from './img/3.png'
import imm from './img/large-exclamation-mark.png'
import immm from './img/license.png'
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';




export const Home = () => (

  <Container  >



     

<br></br>

  <div style={{textAlign:"center",alignItems:"center" ,marginLeft:"auto" ,marginRight:"auto",marginTop:"auto",marginBottom:"auto" ,fontWeight:"bold" }} className="textaliht">
  <Fade bottom>
   <br></br>
   
    <h1   ><img src={im} alt="2"/><span></span> מקימים עסק </h1>
    <h1  ><img src={im} alt="2"/> <img src={immm} alt="2"/> <span></span>מחדשים רישיון </h1>
    <h1 > <img src={imm} alt="2"/><span></span>הגעתם למקום הנכון</h1> <br></br>
  

  <div style={{textAlign:"center",alignItems:"center" ,marginLeft:"auto" ,marginRight:"auto",marginTop:"auto",marginBottom:"auto" ,fontWeight:"bold" }}  className="aa">
    <p></p><p></p><p></p><p></p>
    <h3  className="aa">  <span></span> תכנון העסק   &nbsp;<img className="im" src={i} alt="1"/> </h3>
    <h3  className="aa"><span></span>  התאמת העסק והדרכה בהתאם לצו רישוי עסקים &nbsp;<img className="im" src={i} alt="1"/> </h3>
    <h3  className="aa"> <span></span>  אישור הרשויות המקומיות &nbsp;<img className="im" src={i} alt="1"/> </h3>
    <h3  className="aa"> <span></span> אישור משרד הבריאות &nbsp;<img className="im" src={i} alt="1"/></h3>
    <h3  className="aa" > <span></span>  היתר כסאות שולחנות חיצוני וסגירות חורף &nbsp;<img className="im" src={i} alt="1"/></h3>

    </div>
 
    </Fade >

    </div >



    </Container>
 

)


    ////<a href="https://www.instagram.com/hai_vaizman/"> @hai_vaizman instagram אתר אינטרנט נבנה על ידי  </a>