import React from 'react';
///import {Alert} from "react";
import {  Button, Navbar } from 'react-bootstrap';
import { DropdownButton,ButtonGroup,Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import b from '../img/logo.png'
import nn from '../img/people.png'
import t from '../img2/instagram.png'
import tt from '../img2/facebook.png'
import ttt from '../img2/twitter.png'
import tttt from '../img2/whatsapp.png'
import ttttt from '../img2/gmail.png'
import tttttt from '../img2/hai.png'
import './Navigationdowen.css';


const closeModalButtons = document.querySelectorAll('[data-close-button]')
const overlay = document.getElementById('overlay')
const openModalButtons = document.querySelectorAll('[data-modal-target]')
const Styles = styled.div`



`


const lightness = () => {
  if(true)
 
 
   document.body.style.backgroundColor = "black";
 
 
   else   alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 };
 
 const FontEnlargement = () => {
 if(true)
   document.body.style.zoom="150%"
 
   else   alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 
 
   };
   const small = () => {
 if(true)
     document.body.style.zoom="50%"
 
     else   alert(
       'קיימת תקלה , עמכם הסליחה',
       
       
      );
 
 
     };
 
 
 
 const colors = () => {
 if(true)
 
   document.body.style.backgroundColor = "red";
 
   else alert(
     'קיימת תקלה , עמכם הסליחה',
     
     
    );
 
 };
 
   
 const canclle = () => {
   if(true){
   document.body.style.backgroundColor = "white";
   document.body.style.zoom="100%"
   }
   else
 
   alert(
      'קיימת תקלה , עמכם הסליחה',
      
      
     );
 
 };
 

openModalButtons.forEach(button => {
  button.addEventListener('click', () => {
    const modal = document.querySelector(button.dataset.modalTarget)
    openModal(modal)
  })
})


closeModalButtons.forEach(button => {
  button.addEventListener('click', () => {
    const modal = button.closest('.modal')
    closeModal(modal)
  })
})

function openModal(modal) {
  if (modal == null) return
  modal.classList.add('active')
  overlay.classList.add('active')
}

function closeModal(modal) {
  if (modal == null) return
  modal.classList.remove('active')
  overlay.classList.remove('active')
}


function web(img) {
  var src = img.src;
  window.open("https://www.rishuy-asakim.co.il/web/תקנון-שימוש-באתר-רישוי עסקים ישראל.pdf");



}

////function takla(modal)    {
 /// alert(
 ///    'נגישות האתר נמצאת בטיפול , עמכם הסליחה',
     
     
 ////   );


///}


export const Navigationdowen = () => (

  <div style={{textAlign:"center",alignItems:"center"}}>
   <head>

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script>



 </head>


<div style={{textAlign:"center",alignItems:"center"}}  className='footer-container' >



<Navbar.Brand style={{textAlign:"center",alignItems:"center"}}  className="img"></Navbar.Brand> 
<img  src={b} alt="3" />




  <a style={{color:"black" ,textAlign:"center"}} href="https://www.haimsite.co.il/" target="blank"><img style={{textAlign:"center",alignItems:"center" ,marginLeft:"auto" ,marginRight:"auto",marginTop:"auto",marginBottom:"auto" ,width:"15%",height:"15%" }}  className="haim"  src={tttttt} alt="3" />  haimsite.co.il  <a>- אתר זה נבנה ע"י </a></a>
  <div>
  <a style={{color:"black" ,textAlign:"center", fontSize:"10px"}} href="https://www.rishuy-asakim.co.il/web/תקנון-שימוש-באתר-רישוי עסקים ישראל.pdf" target="blank"> תנאי שימוש </a><a>/</a>
  <a style={{color:"black" ,textAlign:"center", fontSize:"10px"}} href="https://www.rishuy-asakim.co.il/web/תקנון-שימוש-באתר-רישוי עסקים ישראל.pdf" target="blank"> מדיניות פרטיות </a><a>/</a>
  <a style={{color:"black" ,textAlign:"center", fontSize:"10px"}} href="https://rishuy-asakim.co.il/About" target="blank"> אודות </a>


  </div>

  </div>





  </div>

   )


   export default Navigationdowen;

   /////a href="https://www.instagram.com/hai_vaizman/"






   ///<table style={{textAlign:"center",alignItems:"center" ,marginLeft:"auto" ,marginRight:"auto",marginTop:"auto",marginBottom:"auto" }} >
   ///<tr>
   ///{['up'].map((variant) => (
     ///  <DropdownButton style={{}}
      ///   as={ButtonGroup}
      ///   key={variant}
      ///   id={`dropdown-button-drop-${variant}`}
       ///  drop={variant}
       ///  variant="variant"
       ///  title={  <img    src={nn} alt="1"/>}
 
      
      ////   <Dropdown.Item  onClick= {lightness} ><img   src={l} alt="1"/>&nbsp;בהירות</Dropdown.Item>
      ///   <Dropdown.Item  onClick={FontEnlargement}><img   src={ll} alt="1"/>&nbsp;הגדלה</Dropdown.Item>
      ///   <Dropdown.Item   onClick={small}><img   src={lll} alt="1"/>&nbsp;הקטנה</Dropdown.Item>
      ///   <Dropdown.Item  onClick={colors} > <img   src={llll} alt="1"/> החלפת צבעים </Dropdown.Item>
      ///   <Dropdown.Item  onClick={canclle}  >  <img   src={lllll} alt="1"/>  איפוס  </Dropdown.Item>
     ///  </DropdownButton>
    //// ))}
    
   ////</tr>
   ///</table >
 
   
  
 
 
 
 
 
