import React, {Component} from 'react';
import './Contact.css';
import t from './img2/instagram.png'
import tt from './img/waze.png'
import n from './img2/phone.png'
import tttt from './img2/whatsapp.png'
import ttttt from './img2/gmail.png'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap';
import emailjs from "emailjs-com";
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';





///const btnScrollToTop = document.querySelector("#btnScrollToTop");

export default class Contact extends Component { 
  
    constructor(props) {
        super(props);

        

        this.state =
            {
               name:'',
               contactEmail:'',
               contactMessage:''
                
            };

          

          }

          sendEmail(e) {
            e.preventDefault();
    
        emailjs.sendForm('service_udygc8r', 'template_hxeni1g', e.target, 'user_7XjWzaNdKiGR2BG0wBiLt')
            .then((result) => {
                console.log(result.text);
                alert('הודעה נשלחה בהצלחה , ניצור קשר בהקדם! תודה ')
            }, (error) => {
                console.log(error.text);
                alert('הודעה לא נשלחה , נסה שנית')
            });
            e.target.reset()
        }


  emailChek=(event)=>   {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email =event.target.value;
    if(regex.test(email)){
     event.target.style= "background-color: rgba(141, 194, 111, 150)";
     
     }
    else{
        event.target.style= "background-color: rgba(214, 94, 70, 150)";
        

    }
    this.UserLogin  = event.target.value;
  
  }
  





   
    render() {
        
        return (
            <div style={{zIndex:"1"   }}>
           < Container >


 
  <div onSubmit={this.handleSubmit} className="App">
        <p></p>
        <br></br>
       
       
     
  <form id="contact-form" method="POST">
  <br></br>

   



   <div style={{marginLeft:"auto",marginRight:"auto" , justifyContent:"center",alignItems:"center",marginTop:"auto",marginBottom:"auto",textAlign:"center" , zIndex:"1"  }}  className="form-group">
  


       <div style={{zIndex:"1"}} class="wrapper">

       <div style={{zIndex:"1"}} class="icon youtube">
  <div class="tooltip"> התקשרו</div>
  <span><i class="fab fa-youtube"></i></span>
  <a  a href="tel:052-4816141" >  <img  src={n} alt="3" /></a>

</div>
<div style={{zIndex:"1"}}    class="icon twitter">
  <div class="tooltip">וואטסאפ</div>
  <span><i   class="fab fa-twitter"></i></span>
  <a target="_blank"    a href="https://wa.me/972524816141"  >  <img  src={tttt} alt="3" /></a>
</div>




<div style={{zIndex:"1"}} class="icon github">
  <div class="tooltip">אימייל</div>
  <span><i class="fab fa-github"></i></span>
  <a target="_blank" a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to= shani.aviv.arch@gmail.com">  <img  src={ttttt} alt="3" /></a>
</div>


<div style={{zIndex:"1"}} class="icon facebook">
    <div  class="tooltip">הגעה </div>
  <span><i class="fab fa-facebook-f"></i></span>
  <a href={`https://waze.com/ul?q=שוהם 5 רמת גן${this.state.linkLat},${this.state.linkLng}&navigate=yes`} target="_blank" Open in Waze> <img  src={tt} alt="3" /></a>
  </div>

</div>


     
 
 
       
   </div>
   <br></br>   <br></br>

   <div >
   <LightSpeed right>
   <form style={{zIndex:"1"}} onSubmit={this.sendEmail}>

                    <div style={{zIndex:"1"}} className="row pt-5 mx-auto">
                        <div style={{zIndex:"1"}} className="col-8 form-group pt-2 mx-auto">
                     
                   
                        <p style={{textAlign:"center",zIndex:"1" }}  className="t">:השאירו פרטים ונחזור אליכם</p>
                   
                            <input style={{textAlign:"right",zIndex:"1"   }} type="text" className="form-control" placeholder="שם" name="name" required />
                        </div>
                        <div style={{zIndex:"1"}} className="col-8 form-group pt-2 mx-auto">
                            <input style={{textAlign:"right",zIndex:"1"   }}type="email" className="form-control" placeholder="איימל" name="email" required />
                        </div>
                        <div style={{zIndex:"1" }} className="col-8 form-group pt-2 mx-auto">
                            <input style={{textAlign:"right", zIndex:"1" }} type="phone" className="form-control" placeholder="טלפון" name="phone"  />
                        </div>
                        <div style={{zIndex:"1"}} className="col-8 form-group pt-2 mx-auto">
                            <input style={{textAlign:"right",zIndex:"1"   }} type="text" className="form-control" placeholder="נושא" name="subject" required />
                        </div>
                        <div style={{zIndex:"1"}} className="col-8 form-group pt-2 mx-auto">
                            <textarea style={{textAlign:"right",zIndex:"1"   }} className="form-control" id="" cols="30" rows="8" placeholder="גוף ההודעה" name="message" required ></textarea>
                        </div>
                        <div style={{zIndex:"1"}} className="col-8 pt-3 mx-auto" style={{textAlign:'center'}}>
                            <input style={{textAlign:"right",zIndex:"1"   }} type="submit" className="btn btn-info"   value="שליחת הודעה"></input>
  

                        </div>
                     
                    </div>
                 
                </form>
                </LightSpeed>
            </div>
         
   </form>

 <br></br><br></br>


   </div> 
       
   </Container>   
   </div>
            
        );

    }
}

////React.render(<Contact/>, document.getElementById('app'));






////<Button   onClick={ () => { this.scrollStep();}}  className="btnbtn" variant="primary"> למעלה</Button>

///<Button  a href = 'mailto:shani.aviv.arch@gmail.com?body="Yourbody"&subject="a subject".com' className="btnbtn" variant="primary">שליחה למייל</Button>


////<Button  a href="https://wa.me/972551234567" className="btnbtn" >  <img className="im"  src={ccc} alt="3"  a href="https://wa.me/972551234567"  />&nbsp;&nbsp;שליחת הודעה</Button>




///<img className="mobile" src={c} alt="3"/> <img className="mobile" src={cc} alt="3"/>


////<Button target="_blank"  className="teee" variant="Light"  a href="tel:052-4816141"><span></span>  חייגו אלינו &nbsp;<img  src={dd} alt="1"/> </Button><br></br>
///<Button target="_blank"    a href="https://wa.me/972524816141"  className="teee" variant="Light"  >שליחת הודעה  &nbsp;<img src={ccc} alt="3"  /> </Button>
////</Fade>
///<Fade left>
///<Button a href={`https://waze.com/ul?ll=${this.state.linkLat},${this.state.linkLng}&navigate=yes`} target="_blank" Open in Waze   className="teee" variant="Light" > נווטו אלינו &nbsp;< img src={c}  alt="1"/>  </Button>
////Button target="_blank" a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to= shani.aviv.arch@gmail.com" onClick={() => {alert('לחצו אישור לשליחת מייל'); }}  className="teee" variant="Light">שליחה למייל &nbsp; <img src={cc} alt="3"  /></Button>