
import React from 'react'
import { Container } from 'react-bootstrap';
import Bounce from 'react-reveal/Bounce';







export const Accessibility = () => (
   <Container>


    <div className="text">
         <p></p>
     <div>
     <Bounce right>
        <br></br>
  <p>,בהתאם לחוק הנגישות, לשם קבלת היתר זמני,  רישיון עסק או חידוש רישיון </p>
  <p style={{}} >ערים מסוימות מחייבות את העסקים לעמוד בדרישות הנגישות בהתאם לחוק שיוויון  <p>.זכויות לאנשים עם מוגבלויות ותקנותיו</p> </p>

  <p>על בעל העסק להגיש חוות דעת של מורשה נגישות מתו"ס (מבנים, תשתיות וסביבה) ומורשה נגישות השירות, אשר יבחנו בהתאם להוראות הדין על ידי מורשה נגישות <p>.מטעם הרשות</p></p>
  </Bounce>
  
    </div>
  
  
  
  
  
  

   </div>

  


</Container>
  
  
  
  
  )
  
  