import React from 'react'
import c from './img/asterisk.png'
///import cc from './img/down.png'
import { Container   } from 'react-bootstrap';
import LightSpeed from 'react-reveal/LightSpeed';

export const Permitforuseexceeds = () => (

<Container>



<div className="text" >
<LightSpeed right>
<br></br>


<p>על פי הנחיות היועץ המשפטי לממשלה בנוגע לרישוי עסקים במבנים שאינם מוסדרים, במקביל לבדיקת גורמי הרישוי השונים, המחלקה לתכנון ולרישוי הנדסי בודקת אם  <p style={{}} > : הבקשה תואמת את דיני התכנון והבנייה בנושאים הבאים</p> </p>



 

  <br></br>
  <p style={{ fontfamily: 'David'}} > .חוקיות המבנה והיתר הבנייה <img src={c} alt="2"/></p>
  <p>באם אין היתר בנייה - מידת העמידה    <img src={c} alt="2"/></p>
  <p>בהגדרות המאפשרות רישיון עסק&nbsp;&nbsp;&nbsp;</p>
  <p style={{ }} >  .ללא היתר בנייה &nbsp; &nbsp;</p>
  <p> </p>
  <p>התאמת השימוש בנכס לייעוד המבנה  <img src={c} alt="2"/>  </p>
  <p style={{}} >על פי, היתר הבנייה ותכנית בניין עיר&nbsp;&nbsp; </p>
  <p>(תב"ע)&nbsp;&nbsp;&nbsp;</p>
 
  <p style={{}} >זאת במידה והשימוש אינו תואם את היתר הבנייה או התב"ע <p>.(תכנית בניין עיר)</p> </p>
 
  <br></br><br></br>
 
  <p style={{color: "red" , fontSize:"24px" ,  fontfamily: 'David'}} >  .ע"פ תיקון 101 בעל העסק נדרש להגיש בקשה לקבלת היתר לשימוש חורג במערכת הארצית של רישוי זמין  </p>


  </LightSpeed>

  </div>



  
  </Container>




)