import React from 'react'
import './Websiteterms.css';
import { Container } from 'react-bootstrap';
import Roll from 'react-reveal/Roll';




function web(img) {
  var src = img.src;
  window.open("https://www.rishuy-asakim.co.il/web/תקנון-שימוש-באתר-רישוי עסקים ישראל.pdf");



}
















export const Websiteterms = () => (

  <Container>


<br></br>
  <div>
   <br></br>
 
    

<div className="bb">
<Roll top>
<p>.הינו אתר אינטרנט הכולל מידע ושירותים שונים בענף הרישוי עסקים  “rishuy-asakim” אתר </p>
<p>  תקנון אתר זה, המהווה גם לתנאי השימוש באתר נכתבים בלשון זכר יחיד מטעמי נוחות השפה, אך מתייחסים לשני המינים, לרבים, וכן לחברות, גופים ותאגידים משפטיים ו/או עסקיים בצורה שווה </p>
<p>אנא קראו תקנון זה ואת תנאי השימוש בו בעיון רב, בטרם גלישתך באתר , שכן המשך גלישה וצפייה במידע הנמצא באתר ו/או בתכניו מהווים הסכמתך לתקנון האתר (מסמך זה, להלן: "תקנון אתר") במלואו</p>
<p>השימוש באתר ובתכנים שבו ניתנים לשימוש כפי שהם וללא כל התחייבות או אחריות מכל סוג שהוא. השימוש בתכנים המוצגים באתר כפי שמצוינים בהגדרת המונח "האתר", וכן בשירותי האתר ייעשו על אחריותו הבלעדית והמלאה של המשתמש </p>
 <p>.אינה מתחייבת כי התכנים באתר יהיו מעודכנים בכל עת  “rishuy-asakim” אתר</p>
 <p>.אתר זה הינו לשימושו האישי בלבד של המשתמש והוא מוגן בחוק זכויות היוצרים </p>
 <p>ואין לעשות בו כל שימוש ,   “rishuy-asakim” זכויות היוצרים של אתר זה שייכות ל</p>
 <p> .“rishuy-asakim” -מסחרי ללא הרשאה מפורשת בכתב ומראש מ </p>
 <p>אין להפיץ, להשאיל, להציג, להעתיק, לאחסן, לשכפל, לצטט, לשלוח בדואר רגיל או בדואר אלקטרוני, לפרסם בכתב, באינטרנט, או בכל מדיום אחר, אלא בהסכמה ברורה </p>
 <p>. “rishuy-asakim” - ומפורשת בכתב ומראש </p>
 </Roll>
</div>










    </div>
  














 
    
    </Container>
 
   
)
